export const Questions = {
  __1: {
    q__: `사람들이 평가하는<br/><b>나는</b>`,
    a__1: `<b>따뜻한</b> 사람`,
    a__2: `<b>유능한</b> 사람`,
  },
  __2: {
    q__: `<b>자유 시간</b><br/>이 있을 때 나는`,
    a__1: `<b>혼자</b> 무엇을 할지<br/>먼저 생각한다`,
    a__2: `<b>누구와</b> 만나 무엇을 할지<br/>먼저 생각한다`,
  },
  __3: {
    q__: `나에게 있어 <br/><b>더 중요한 것</b>은`,
    a__1: `<b>현재</b> 이 순간`,
    a__2: `앞으로의 <b>미래와 가능성</b>`,
  },
  __4: {
    q__: `<b>일</b>을 할 때`,
    a__1: `일의 진행사항, 세부계획, 일정 등을 <b>꼼꼼히</b> 짜는 것을 선호`,
    a__2: `대략적인 진행사항을 정해두고 <b>유연하게</b> 대처하는 것을 선호`,
  },
  __5: {
    q__: `나와 <b>의견</b>이 <br/>다른 사람에게 반박할 때`,
    a__1: `내 <b>생각과 논리</b>가 더 옳다는 것을 증명하고 싶어`,
    a__2: `내 <b>의견</b>을 상대방이 기분 나쁘지 않게 전달하고 싶어`,
  },
  __6: {
    q__: `<b>생각</b>을 표현할 때`,
    a__1: `<b>말</b>로 하는 것 선호`,
    a__2: `<b>글</b> 쓰는 것을 선호`,
  },
  __7: {
    q__: `내 <b>방</b>이<br/>정리되지 않은 상태라면`,
    a__1: `<b>그럴 리 없다</b>(늘 정리되어 있음) 혹은 스트레스를 받는다`,
    a__2: `가끔 스트레스 받긴 하나 크게 <b>개의치 않는다</b>`,
  },
  __8: {
    q__: `좀 더 선호하는<br/><b>영화 장르</b>는`,
    a__1: `실제 사건이나 일어날 법한 일을 기반으로 한 <b>현실적인</b> 영화`,
    a__2: `존재하지 않는 세계나<br/><b>공상 세계관</b>을 다루는 영화`,
  },
  __9: {
    q__: `학교에서 상을 받은 당신, <br/>무슨 <b>상</b>을 받았을까?`,
    a__1: `<b>토론 대회</b> 상 혹은 경시 대회 상`,
    a__2: `품행 우수상 혹은 <b>봉사 활동상</b>`,
  },
  __10: {
    q__: `<b>낯선 사람</b>이 많은 곳에<br/>초대받았다면`,
    a__1: `썩 편하진 않지만<br/><b>크게 상관없다</b>`,
    a__2: `너무 <b>싫다</b>`,
  },
  __11: {
    q__: `<b>나</b>를 더 잘 설명하는 쪽에<br/>가까운 것은`,
    a__1: `<b>체계적</b>, 뚜렷한 기준,<br/>분명한 목적 의식`,
    a__2: `유유자적, <b>자율적</b>, 수용적`,
  },
  __12: {
    q__: `나에게 더 기분 나쁜 <br/><b>말</b>은`,
    a__1: `<b>상상력</b>이 부족하구나`,
    a__2: `<b>관찰력</b>이 부족하구나`,
  },
};

// export const Q_highlight_word = {
//   1: "나는",
//   2: "자유 시간",
//   3: "더 중요한 것",
//   4: "일",
//   5: "의견",
//   6: "생각",
//   7: "방",
//   8: "영화",
//   9: "상",
//   10: "낯선 사람",
//   11: "설명",
//   12: "말",
// };

// export const A_highlight_word = {
//   1: ["따뜻한", "유능한"],
//   2: ["혼자", "누구와"],
//   3: ["현재", "앞으로"],
//   4: ["꼼꼼히", "유연하게"],
//   5: ["생각과 논리", "의견"],
//   6: ["말", "글"],
//   7: ["그럴 리 없다", "개의치 않는다"],
//   8: ["현실적인", "존재하지 않는"],
//   9: ["토론 대회", "봉사 활동"],
//   10: ["크게 상관없다", "너무 싫다"],
//   11: ["체계적", "자율적"],
//   12: ["상상력", "관찰력"],
// };
