import { writable, readable, derived, get } from "svelte/store";

function createLoading() {
  const { subscribe, set, update } = writable(false);
  return {
    subscribe,
    set: () => set(true),
    reset: () => set(false),
  };
}

function createName() {
  const { subscribe, set, update } = writable("");
  return {
    subscribe,
    get: () => update((n) => n),
    set: (n) => set(n),
    reset: () => set(""),
  };
}

function createPage() {
  const { subscribe, set, update } = writable(0);

  return {
    subscribe,
    current: () => update((v) => v),
    prev: () => update((v) => v - 1),
    next: () => update((v) => v + 1),
    reset: () => set(0),
  };
}

function createAnswers() {
  let initializeAnswers = {
    page1: 0,
    page2: 0,
    page3: 0,
    page4: 0,
    page5: 0,
    page6: 0,
    page7: 0,
    page8: 0,
    page9: 0,
    page10: 0,
    page11: 0,
    page12: 0,
  };
  const { subscribe, set, update } = writable(initializeAnswers);

  return {
    subscribe,
    get: () => update((a) => a),
    set: (page, input) =>
      update((a) => {
        const prev = { ...a };
        prev[`page${page}`] = input;
        return prev;
      }),
    reset: () => set(initializeAnswers),
  };
}

function checkHistory() {
  // 유저 이름, 페이지와 이전 페이지까지의 답안이 저장되어 있는지 확인,
}

export function calculateResult(answer) {
  const checkAnswers = {
    E: 0,
    I: 0,
    S: 0,
    N: 0,
    F: 0,
    T: 0,
    J: 0,
    P: 0,
  };

  const checkType = [
    ["F", "T"],
    ["I", "E"],
    ["S", "N"],
    ["J", "P"],
    ["T", "F"],
    ["E", "I"],
    ["J", "P"],
    ["S", "N"],
    ["T", "F"],
    ["E", "I"],
    ["J", "P"],
    ["N", "S"],
  ];
  // insert user answer to checkAnswers checking type
  for (const page in answer) {
    const p = page.slice(4);
    const res = checkType[p - 1][answer[`page${p}`] - 1];
    checkAnswers[res] += 1;
  }
  const EI = checkAnswers.E > checkAnswers.I ? "e" : "i";
  const SN = checkAnswers.S > checkAnswers.N ? "s" : "n";
  const FT = checkAnswers.F > checkAnswers.T ? "f" : "t";
  const JP = checkAnswers.J > checkAnswers.P ? "j" : "p";
  return `${EI + SN + FT + JP}`;
}

function createResult() {
  const { subscribe, set, update } = writable("");

  return {
    subscribe,
    get: () => update((r) => r),
    set: (r) => set(r),
  };
}

export const name = createName();
export const page = createPage();
export const answers = createAnswers();
export const result = createResult();
export const isLoading = createLoading();
