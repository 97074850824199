export const Results = {
  workaholic: {
    name: "워커홀릭 야근요정",
    path: "workaholic",
    img_url: "/img/1_ENTJ.png",
    type: "entj",
    power: "일벌이기",
    desc:
      "큰 그림을 다양한 전략으로 이뤄가는 창의적인 능력자입니다. 생각한 바를 이루기 위한 일을 벌이는 것이 당신의 핵심능력이죠. 벌인 일을 수습하기 위해 야근을 밥 먹듯이 해야하는 운명입니다.",
    additional_powers: [
      {
        name: "야근하기",
        percentage: 95,
      },
      {
        name: "카리스마",
        percentage: 80,
      },
      {
        name: "리더십",
        percentage: 70,
      },
    ],
    tag: ["쾌적한일공간", "코워킹", "디지털노마드"],
    tag_desc: `창의적인 생각이 퐁퐁,<br/>일이 잘되는 공간들 모음`,
    recommandation: {
      name: "공유오피스",
      img_url: "/img/1_공유오피스.jpg",
      theme_url: "http://bit.ly/MyPowerSpace1",
    },
    soulmate: "meong",
  },
  whysoserious: {
    name: "혼자 심각한 지식인",
    path: "whysoserious",
    img_url: "/img/2_INTJ.png",
    type: "intj",
    power: "한우물파기",
    desc:
      "똑똑하고 독립심이 강한 당신의 핵심능력은 한우물파기입니다. 열정있는 분야에 대해서 집요하게 파고드는 스타일입니다. 다만 말수와 표정의 변화가 적어 사람들이 오해할 수 있으니 가끔 미소를 지어보세요.",
    additional_powers: [
      {
        name: "논리",
        percentage: 90,
      },
      {
        name: "무표정",
        percentage: 85,
      },
      {
        name: "지식습득",
        percentage: 70,
      },
    ],
    tag: ["팀플", "스터디룸", "독서모임"],
    tag_desc: `자기계발이 절로되는<br/>공부하기 좋은 공간들`,
    recommandation: {
      name: "스터디룸",
      img_url: "/img/2_스터디룸.jpg",
      theme_url: "http://bit.ly/MyPowerSpace2",
    },
    soulmate: "hipsters",
  },
  jabbeok: {
    name: "자뻑 일잘러",
    path: "jabbeok",
    img_url: "/img/3_ESTJ.png",
    type: "estj",
    power: "일처리하기",
    desc:
      "계획을 세우고 조직적으로 일을 이뤄가는 프로일잘러입니다. 당황하지 않고 특유의 냉철함으로 일처리하는 능력이 이 캐릭터의 핵심능력입니다. 감정에 휘둘리지 않고 가끔은 잔소리를 빙자한 독설을 날리기도 합니다.",
    additional_powers: [
      {
        name: "잔소리",
        percentage: 95,
      },
      {
        name: "계획세우기",
        percentage: 80,
      },
      {
        name: "신중함",
        percentage: 60,
      },
    ],
    tag: ["업무환경", "코워킹", "사무실"],
    tag_desc: `더 넓고 자유롭게 이용 가능한<br/>쾌적한 업무 공간 모음`,
    recommandation: {
      name: "공유오피스",
      img_url: "/img/3_공유오피스.jpg",
      theme_url: "http://bit.ly/MyPowerSpace3",
    },
    soulmate: "hitthebone",
  },
  showmethemoney: {
    name: "시크한 머니콜렉터",
    path: "showmethemoney",
    img_url: "/img/4_ISTJ.png",
    type: "istj",
    power: "돈세기",
    desc:
      "당신은 다른 사람에게 피해주는 것을 극도로 싫어하는 시크한 사람입니다. 그러한 당신의 핵심능력은 돈세기입니다. 단돈 1원까지도 꼼꼼하게 허투루 쓰는 일이 없어 현금 부자가 될 가능성이 높습니다.",
    additional_powers: [
      {
        name: "거스름돈받기",
        percentage: 90,
      },
      {
        name: "가계부쓰기",
        percentage: 85,
      },
      {
        name: "절약",
        percentage: 65,
      },
    ],
    tag: ["세미나룸", "회의실", "워크샵"],
    tag_desc: `시크한 회의를 위해,<br/>깔끔하고 쾌적한 회의실 공간들`,
    recommandation: {
      name: "회의실",
      img_url: "/img/4_회의실.jpg",
      theme_url: "http://bit.ly/MyPowerSpace4",
    },
    soulmate: "4songs1000won",
  },
  hipsters: {
    name: "성수동 갬성 힙스터",
    path: "hipsters",
    img_url: "/img/5_ENFP.png",
    type: "enfp",
    power: "기웃거리기",
    desc:
      "사람을 좋아하고 누구와도 금방 친구가 되는 감성 힙스터입니다. 외출을 좋아해 여기저기 기웃거리는 것이 주요 핵심능력입니다. 어디에서든 인싸로 인정받지만 가끔은 외로워 보여 보호 본능을 불러 일으키기도 합니다. 종종 소음 민원을 일으키는 요주의 대상입니다.",
    additional_powers: [
      {
        name: "떠들기",
        percentage: 95,
      },
      {
        name: "등장하기",
        percentage: 80,
      },
      {
        name: "귀여움",
        percentage: 60,
      },
    ],
    tag: ["파티룸", "브라이덜샤워", "분위기"],
    tag_desc: `블링블링 투나잇<br/>파티를 위한 공간들`,
    recommandation: {
      name: "파티룸",
      img_url: "/img/5_파티룸.jpg",
      theme_url: "http://bit.ly/MyPowerSpace5",
    },
    soulmate: "whysoserious",
  },
  meong: {
    name: "방구석 몽상가",
    path: "meong",
    img_url: "/img/6_INFP.png",
    type: "infp",
    power: "멍때리기",
    desc:
      "외출을 즐기지 않는 방구석 몽상가의 핵심능력은 멍때리기입니다. 멍 때리며 다양한 상상의 나래를 펼치기도 합니다. 몸은 방구석에 있어도 상상 속에서는 온 세상을 탐험하고 있네요. 타고난 멍때리기로 유연한 뇌를 소유하고 있습니다.",
    additional_powers: [
      {
        name: "집에있기",
        percentage: 97,
      },
      {
        name: "상상하기",
        percentage: 85,
      },
      {
        name: "숨겨진열정",
        percentage: 65,
      },
    ],
    tag: ["한옥마을", "한옥대관", "워크샵"],
    tag_desc: `색다른 분위기가 매력적인<br/>한옥 공간 모음`,
    recommandation: {
      name: "한옥",
      img_url: "/img/6_한옥.jpg",
      theme_url: "http://bit.ly/MyPowerSpace6",
    },
    soulmate: "workaholic",
  },
  lovemyself: {
    name: "까칠한 나르시스트",
    path: "lovemyself",
    img_url: "/img/7_ENTP.png",
    type: "entp",
    power: "이기기",
    desc:
      "다른 사람과 논쟁하는 것을 두려워 하지 않는 당신의 핵심 능력은 이기기입니다. 웬만해선 상처받지 않는 멘탈의 소유자시군요. 타고난 자기애에 까칠함과 말빨이 더해져 모든 상황에서 승리하는 진정한 나르시스트랍니다.",
    additional_powers: [
      {
        name: "말싸움",
        percentage: 95,
      },
      {
        name: "나르시시즘",
        percentage: 85,
      },
      {
        name: "코웃음",
        percentage: 40,
      },
    ],
    tag: ["루프탑", "소모임", "바베큐"],
    tag_desc: `하늘 아래 프라이빗한<br/>루프탑 공간들`,
    recommandation: {
      name: "루프탑",
      img_url: "/img/7_루프탑.jpg",
      theme_url: "http://bit.ly/MyPowerSpace7",
    },
    soulmate: "ommanipadmehum",
  },
  hitthebone: {
    name: "팩폭 마스터",
    path: "hitthebone",
    img_url: "/img/8_INTP.png",
    type: "intp",
    power: "뼈때리기",
    desc:
      "본질에 대한 호기심이 강하고 분석을 정확히 하는 당신의 핵심능력은 뼈때리기입니다. 누구에게 속는 것을 싫어해서 상황과 사물을 정확히 보고자 하죠. 팩트로 뼈때리기 고수입니다.",
    additional_powers: [
      {
        name: "분석하기",
        percentage: 95,
      },
      {
        name: "자기주장",
        percentage: 80,
      },
      {
        name: "몹쓸호기심",
        percentage: 50,
      },
    ],
    tag: ["빔프로젝터", "영상회", "방구석콘서트"],
    tag_desc: `좋아하는 영화, 영상<br/>보기 좋은 공간들`,
    recommandation: {
      name: "빔프로젝터가 있는 공간",
      img_url: "/img/8_빔프로젝터가있는공간.jpg",
      theme_url: "http://bit.ly/MyPowerSpace8",
    },
    soulmate: "jabbeok",
  },
  takemymoney: {
    name: "욜로 소비엔젤",
    path: "takemymoney",
    img_url: "/img/9_ESTP.png",
    type: "estp",
    power: "돈쓰기",
    desc:
      "당신은 세상에 대한 호기심과 도전의식이 강하고, 무엇이든 경험하고 싶은 욜로족입니다. 당신의 핵심 능력은 돈쓰기입니다. 새로운 경험이라면 언제나 뛰어들 준비가 되었기 때문에 손엔 늘 결제를 위한 카드가 들려 있습니다.",
    additional_powers: [
      {
        name: "결제하기",
        percentage: 95,
      },
      {
        name: "여행",
        percentage: 85,
      },
      {
        name: "도전",
        percentage: 50,
      },
    ],
    tag: ["공연장", "미니콘서트", "공연홀"],
    tag_desc: `누구나 대관 가능한<br/>작은 공연장 공간들`,
    recommandation: {
      name: "공연장",
      img_url: "/img/9_공연장.jpg",
      theme_url: "http://bit.ly/MyPowerSpace9",
    },
    soulmate: "likeit",
  },
  artistwithhammer: {
    name: "망치든 예술가",
    path: "artistwithhammer",
    img_url: "/img/10_ISTP.png",
    type: "istp",
    power: "손쓰기",
    desc:
      "도구와 연장을 잘 다루며 무언가의 작동 원리를 금방 파악하는 당신의 핵심능력은 손쓰기 입니다. 누구에게도 의존하지 않는 독립적인 캐릭터로 예술적인 감각도 있고 망치 하나만 있으면 어디서든 살아 남을 수 있는 생존력의 소유자이기도 합니다.",
    additional_powers: [
      {
        name: "혼자놀기",
        percentage: 95,
      },
      {
        name: "만들기",
        percentage: 80,
      },
      {
        name: "싫어하기",
        percentage: 65,
      },
    ],
    tag: ["공방", "원데이클래스", "취미"],
    tag_desc: `예술혼을 불태우기 좋은<br/>다양한 작업실 공간들`,
    recommandation: {
      name: "내 능력의 소울메이트",
      img_url: "/img/10_작업실.jpg",
      theme_url: "http://bit.ly/MyPowerSpace10",
    },
    soulmate: "checkurdm",
  },
  ihatedaechung: {
    name: "열정 만수르",
    path: "ihatedaechung",
    img_url: "/img/11_ENFJ.png",
    type: "enfj",
    power: "부추기기",
    desc:
      "세상을 향한 열정이 가득한 당신의 핵심 능력은 다른 사람을 부추기는 것입니다. 타고난 리더십과 헌신적인 마음으로 사람들의 사랑과 인정을 받지만 동시에 다른 사람을 향한 참견과 간섭으로 미움을 받을 때도 있군요.",
    additional_powers: [
      {
        name: "간섭하기",
        percentage: 95,
      },
      {
        name: "긍정의말",
        percentage: 85,
      },
      {
        name: "리더십",
        percentage: 70,
      },
    ],
    tag: ["팀워크", "워크샵", "세미나"],
    tag_desc: `팀워크를 다지기 좋은<br/>다양한 워크샵 공간들`,
    recommandation: {
      name: "워크샵 공간",
      img_url: "/img/11_워크샵공간.jpg",
      theme_url: " http://bit.ly/MyPowerSpace11",
    },
    soulmate: "givemewater",
  },
  ommanipadmehum: {
    name: "수줍은 궁예",
    path: "ommanipadmehum",
    img_url: "/img/12_INFJ.png",
    type: "infj",
    power: "꿰뚫어보기",
    desc:
      "보이지 않는 것을 보는 통찰력의 소유자인 당신의 핵심 능력은 꿰뚫어보기입니다. 인간관계에서 조금은 소극적인것 같지만 사실 모든 것을 보고 알고 있군요. 그러한 당신의 핵심능력은 관심법을 능가합니다.",
    additional_powers: [
      {
        name: "넘겨짚기",
        percentage: 95,
      },
      {
        name: "따뜻한 눈빛",
        percentage: 85,
      },
      {
        name: "생각에잠기기",
        percentage: 60,
      },
    ],
    tag: ["바베큐", "루프탑", "생일파티"],
    tag_desc: `바베큐를 즐길 수 있는<br/>루프탑 공간들`,
    recommandation: {
      name: "바베큐 루프탑",
      img_url: "/img/12_바베큐루프탑.jpg",
      theme_url: "http://bit.ly/MyPowerSpace12 ",
    },
    soulmate: "lovemyself",
  },
  checkurdm: {
    name: "섬세한 프로디엠러",
    path: "checkurdm",
    img_url: "/img/13_ESFJ.png",
    type: "esfj",
    power: "메시지보내기",
    desc:
      "사람을 좋아하고 애정 많은 당신의 핵심능력은 메시지보내기입니다. 먼저 다가가고 적극적으로 인간 관계를 하는 성격으로 상담을 요청하는 사람도 많군요. 다만 마음을 먼저 쓰는 쪽이다보니 가끔은 도망가고 싶을 때가 있네요.",
    additional_powers: [
      {
        name: "선팔&맞팔",
        percentage: 95,
      },
      {
        name: "공감하기",
        percentage: 85,
      },
      {
        name: "웃어주기",
        percentage: 70,
      },
    ],
    tag: ["공유주방", "쿠킹스튜디오", "키친"],
    tag_desc: `함께 요리하기 좋은<br/>공유주방 공간들`,
    recommandation: {
      name: "공유주방",
      img_url: "/img/13_공유주방.jpg",
      theme_url: "http://bit.ly/MyPowerSpace13 ",
    },
    soulmate: "artistwithhammer",
  },
  likeit: {
    name: "좋아요인간봇",
    path: "likeit",
    img_url: "/img/14_ISFJ.png",
    type: "isfj",
    power: "좋아요누르기",
    desc:
      "다른 사람을 배려하고 잘 챙기기도 하는 당신의 핵심 능력은 좋아요 누르기입니다. 신중하고 꼼꼼한 면도 있고 사람과의 관계에서 헌신적인 편이네요. 다른 사람과의 대화에서 리액션을 잘해 사람들이 편안해 하지요. SNS 좋아요는 당신을 위해 존재하는 기능입니다.",
    additional_powers: [
      {
        name: "방청객모드",
        percentage: 95,
      },
      {
        name: "출석하기",
        percentage: 85,
      },
      {
        name: "댓글남기기",
        percentage: 70,
      },
    ],
    tag: ["워크샵", "모임장소", "행사"],
    tag_desc: `워크샵부터 소모임까지<br/>다양한 목적의 공간들`,
    recommandation: {
      name: "다목적홀",
      img_url: "/img/14_다목적홀.jpg",
      theme_url: "http://bit.ly/MyPowerSpace14 ",
    },
    soulmate: "takemymoney",
  },
  "4songs1000won": {
    name: "코노 매니아",
    path: "4songs1000won",
    img_url: "/img/15_ESFP.png",
    type: "esfp",
    power: "잘먹고 잘자기",
    desc:
      "사람을 좋아하고 딱히 큰 고민이 없는 당신의 핵심능력은 잘먹고 잘자기입니다. 흥이 넘쳐 어딜 가든 분위기 메이커죠. 콧노래와 댄스를 즐기기도 합니다. 가끔은 소비를 절제하지 못해 통장 잔고가 일찍 바닥나 곤란해하지만, 크게 걱정하지 않는군요.",
    additional_powers: [
      {
        name: "흥내기",
        percentage: 95,
      },
      {
        name: "멋부리기",
        percentage: 80,
      },
      {
        name: "결제하기",
        percentage: 70,
      },
    ],
    tag: ["댄스연습", "케이팝", "커버댄스"],
    tag_desc: `흥을 표현하기 좋은<br/>댄스 연습실 공간들`,
    recommandation: {
      name: "댄스 연습실",
      img_url: "/img/15_댄스연습실.jpg",
      theme_url: "http://bit.ly/MyPowerSpace15 ",
    },
    soulmate: "showmethemoney",
  },
  givemewater: {
    name: "땀많은 아티스트",
    path: "givemewater",
    img_url: "/img/16_ISFP.png",
    type: "isfp",
    power: "눈치보기",
    desc:
      "배려가 가득한 따뜻한 마음을 가지셨군요. 다른이의 감정을 자주 살피는 당신의 핵심능력은 눈치보기입니다. 자신만의 예민한 감성과 따뜻한 시선을 가진 아티스트로 표현할 수 있는 능력이 있습니다. 다만 눈치를 보느라 땀이 많이 날 때가 있습니다.",
    additional_powers: [
      {
        name: "참기",
        percentage: 95,
      },
      {
        name: "배려하기",
        percentage: 85,
      },
      {
        name: "남위로하기",
        percentage: 70,
      },
    ],
    tag: ["피아노연습실", "레슨", "취미피아노"],
    tag_desc: `시간단위로 빌릴 수 있는<br/>피아노 연습실 모음`,
    recommandation: {
      name: "피아노연습실",
      img_url: "/img/16_피아노연습실.jpg",
      theme_url: "http://bit.ly/MyPowerSpace16 ",
    },
    soulmate: "ihatedaechung",
  },
};
