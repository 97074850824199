<script>
  import { onMount } from "svelte";

  import Title from "../assets/img/title.svelte";
  import Footer from "../assets/img/footer.svelte";
  export let name;
  export let page;

  (function (i, s, o, g, r, a, m) {
    i["GoogleAnalyticsObject"] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
  );
  ga("create", "UA-123322100-4", "auto");

  function clickStart() {
    if (!$name.length) {
      alert("이름을 입력해주세요.");
      return;
    }
    ga(
      "send",
      "event",
      "click",
      "start_button_click",
      "시작하기 버튼 클릭 횟수"
    );
    page.next();
  }
</script>

<main class="main" role="main">
  <div class="header">
    <div class="sub_title left size_14">핵심능력 테스트</div>
  </div>
  <div class="img_box">
    <div class="title_img">
      <svg
        width="241px"
        height="181px"
        viewBox="0 0 241 181"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"><Title /></svg>
    </div>
  </div>
  <div class="form_box">
    <input
      id="name"
      name="name"
      type="text"
      class="box_rounded default"
      placeholder="이름을 입력해주세요"
      bind:value={$name}
      maxlength="14"
      on:input={(e) => {
        if (e.target.value.length > 14) {
          return;
        }
        localStorage.setItem('user_name', e.target.value);
        return name.set(e.target.value);
      }} />
    <div class="box_rounded fill">
      <button on:click={() => clickStart()} type="button">능력테스트 시작</button>
    </div>
  </div>
  <div class="footer">
    <div class="sp logo" />
  </div>
</main>
