<script>
  import { navigateTo } from "svelte-router-spa";
  import {
    name,
    page,
    answers,
    calculateResult,
    result,
  } from "~/store/store.js";
  import Main from "./Main.svelte";
  import Test from "./Test.svelte";
  import { Results } from "~/assets/data/results";
  export let currentRoute;
  export let params;

  let userData = { name: "", answers: {} };

  name.subscribe((n) => (userData.name = n));
  answers.subscribe((v) => (userData.answers = { ...v }));
  page.subscribe((v) => {
    if (v === 13) {
      console.log("result loading");
      const type = calculateResult(userData.answers);
      const path = getCharacterName(type);
      result.set(type);
      window.location.href = `/${path}.html?`;
      navigateTo(`/${path}.html`);
    }
  });

  function getCharacterName(type) {
    for (const character in Results) {
      if (Results[character].type === type) {
        return character;
      }
    }
  }
</script>

<!-- 
<svelte:head>
  <meta property="og:title" content="남들이 보는 나의 핵심능력이 궁금하다면?" />
  <meta
    property="og:description"
    content="스페이스클라우드 핵심능력 테스트" />
  <meta property="og:url" content="http://mypower.spacecloud.kr" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://mypower.spacecloud.kr/img/sample.png" />
</svelte:head> -->

{#if $page === 0}
  <Main name={{ ...name }} page={{ ...page }} />
{:else if 0 < $page && $page <= 12}
  <Test
    name={$name}
    currentPage={$page}
    page={{ ...page }}
    answers={{ ...answers }} />
{/if}
