<script>
  import { onMount } from "svelte";
  import { Results } from "../assets/data/results";
  import IconKakao from "../assets/img/ico_kakao.svelte";
  import IconFacebook from "../assets/img/ico_fb.svelte";
  import IconShare from "../assets/img/ico_share.svelte";
  import { name } from "~/store/store.js";

  export let currentRoute;
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  let character = Results[currentRoute.namedParams.type];
  (function (i, s, o, g, r, a, m) {
    i["GoogleAnalyticsObject"] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
  );
  ga("create", "UA-123322100-4", "auto");

  function shareFacebook() {
    ga("send", "event", "share", "share_button_click", "공유 버튼 클릭 횟수");
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmypower.spacecloud.kr%2F${character.path}&amp;src=sdkpreparse`,
      600,
      450
    );
  }

  function shareKakao() {
    ga("send", "event", "share", "share_button_click", "공유 버튼 클릭 횟수");
    Kakao.Link.sendScrap({
      requestUrl: `https://mypower.spacecloud.kr/${character.path}`,
    });
  }

  function copyLink() {
    ga("send", "event", "share", "share_button_click", "공유 버튼 클릭 횟수");
    var temporaryElement = document.createElement("input"),
      text = window.location.href;

    document.body.appendChild(temporaryElement);
    temporaryElement.value = text;
    temporaryElement.select();
    document.execCommand("copy");
    document.body.removeChild(temporaryElement);
    alert("URL이 클립보드에 복사되었습니다");
  }
</script>

<!-- <svelte:head>
  <meta property="og:title" content="스페이스클라우드 핵심능력 테스트" />
  <meta
    property="og:description"
    content="(결과)남들이 보는 나의 핵심능력이라니…^.T  너도 궁금하면 클릭!" />
  <meta
    property="og:url"
    content={`https://mypower.spacecloud.kr/${character.path}`} />
  <meta property="og:type" content="website" />
  <meta property="og:image" content={Results[character.soulmate].img_url} />
</svelte:head> -->

<result class="result">
  <!-- summary -->
  <div class="text_box size_24">
    <b>{$name ? $name : ''}</b> 의 핵심능력은<br />
    <span class="highlight white">{character.power}</span>입니다.
  </div>
  <div class="img_box">
    <img src={character.img_url} class="image padding" alt="character" />
  </div>

  <div class="title size_24"><b>"{character.name}"</b></div>
  <div class="text size_16">{character.desc}</div>

  <!-- divider -->
  <div class="divide_container">
    <div class="divider" />
  </div>

  <!-- additional power top3 -->
  <div class="title size_20">추가 능력 BEST 3</div>
  <ul class="status_bar_box">
    {#each character.additional_powers as power, i}
      <li>
        <div class="title_container">
          <span class="size_16">{power.name}</span>
          <span class="font_purp">{power.percentage}%</span>
        </div>
        <div class="default_bar">
          <span class="active_bar" style={`width: ${power.percentage}%`} />
        </div>
      </li>
    {/each}
  </ul>

  <!-- place recommanded -->
  <div class="title size_20">내 능력이 빛나는 공간</div>
  <div class="content_box height_298">
    <a target="_blank" href={character.recommandation.theme_url}>
      <img
        class="image top_rounded"
        src={character.recommandation.img_url}
        alt="place" />
      <span class="btn">
        <button
          class="mid_center active">{character.recommandation.name}</button>
      </span>
      <div class="inner">
        {@html character.tag_desc}
        <div>
          {#each character.tag as tag, i}
            <span class="btn_rounded">{`#${tag}`}</span>
          {/each}
        </div>
      </div>
    </a>
  </div>
  <!-- soulmate -->
  <div class="title size_20">내 능력의 소울 메이트</div>
  <div class="content_box height_264">
    <img
      src={Results[character.soulmate].img_url}
      class="image padding"
      alt="character" />
    <div class="bottom white">{Results[character.soulmate].name}</div>
  </div>
  <!-- share -->
  <div class="btns share">
    <span
      class="oval fb-share-button"
      data-href={`https://mypower.spacecloud.kr/${character.path}`}
      data-layout="button_count"
      data-size="small">
      <button
        class="facebook fb-xfbml-parse-ignore"
        target="_blank"
        on:click={() => shareFacebook()}>
        <svg
          width="9px"
          height="16px"
          viewBox="0 0 9 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <IconFacebook />
        </svg>
      </button>
    </span>
    <span class="oval">
      <button id="kakao" class="kakao" on:click={() => shareKakao()}>
        <svg
          width="16px"
          height="14px"
          viewBox="0 0 16 14"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <IconKakao />
        </svg>
      </button></span>

    <span class="oval">
      <button id="shareUrl" on:click={() => copyLink()}>
        <svg
          width="17px"
          height="17px"
          viewBox="0 0 17 17"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <IconShare />
        </svg>
      </button>
    </span>
  </div>
  <div class="btns">
    <div class="btn">
      <button
        class="see_more active"
        target="_blank"
        on:click={() => window.open(character.recommandation.theme_url, '_blank')}>내
        능력이 빛나는 공간 더 보기</button>
    </div>
    <div class="btn">
      <button
        class="default"
        on:click={() => (window.location.href = '/')}>테스트 다시하기</button>
    </div>
  </div>
  <div class="footer">
    <div class="sp logo" />
  </div>
</result>
