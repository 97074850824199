<script>
  import { Route, Router } from "svelte-router-spa";
  import { routes } from "./routes/routes";
  import { navigateTo } from "svelte-router-spa";
  import {
    name,
    page,
    answers,
    calculateResult,
    result,
    isLoading,
  } from "~/store/store.js";
  import Style from "~/style.svelte";
  import { onMount } from "svelte";

  export let currentRoute;
  let params = {};
  let userData = { name: "", answers: {} };

  name.subscribe((n) => (userData.name = n));
  answers.subscribe((v) => (userData.answers = { ...v }));
  page.subscribe((v) => {
    if (v === 13) {
      const { type } = calculateResult(userData.answers);
      result.set(type);
    }
  });
</script>

<!-- <Style type="css" /> -->

<body>
  <div class="app">
    <section class="section">
      <Router {routes}>
        <Route {currentRoute} {...params} {name} />
      </Router>
    </section>
  </div>
</body>
