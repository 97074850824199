<script>
  import Loading from "../components/Loading.svelte";
  import { Questions } from "../assets/data/questions.js";
  import { isLoading } from "../store/store.js";
  export let name;
  export let page;
  export let answers;
  export let currentPage;

  let active__1 = false;
  let active__2 = false;
  let buttonDisabled = false;

  page.subscribe((v) => {
    buttonDisabled == false;
    active__1 = false;
    active__2 = false;
  });

  function selectButton(buttonNumber) {
    buttonDisabled = true;

    if (buttonNumber === 1) {
      active__1 = true;
      active__2 = false;
    }
    if (buttonNumber === 2) {
      active__1 = false;
      active__2 = true;
    }
    answers.set(currentPage, buttonNumber);

    if (currentPage == 12) {
      setTimeout(() => {
        isLoading.set();
      }, 1500);
      setTimeout(() => {
        isLoading.reset();
        page.next();
        buttonDisabled = false;
      }, 3500);
    } else {
      setTimeout(() => {
        page.next();
        buttonDisabled = false;
      }, 300);
    }
  }
</script>

<test class="test">
  <div class="header flex col">
    <span class="sub_title left size_14">핵심능력 테스트</span>
    {#if !$isLoading}
      <span class="sub_title right font_14">{`${currentPage}/12`}</span>
    {/if}
  </div>
  {#if $isLoading}
    <!-- loading -->
    <div class="content padding_20">
      <Loading />
    </div>
  {:else}
    <div class="content">
      <div class="text">
        {@html Questions[`__${currentPage}`].q__}
      </div>
      <div class="btns">
        <div class="btn">
          <button
            class={active__1 ? 'active' : 'default'}
            on:click={() => selectButton(1)}
            disabled={buttonDisabled}>
            {@html Questions[`__${currentPage}`].a__1}
          </button>
        </div>
        <div class="versus">VS</div>
        <div class="btn">
          <button
            class={active__2 ? 'active' : 'default'}
            on:click={() => selectButton(2)}
            disabled={buttonDisabled}>
            {@html Questions[`__${currentPage}`].a__2}
          </button>
        </div>
      </div>
    </div>
  {/if}
  <!-- footer -->
  <div class="footer">
    <div class="sp logo" />
  </div>
</test>
