import Home from "~/container/Home.svelte";
import Result from "~/container/Result.svelte";

export const routes = [
  {
    name: "/",
    component: Home,
  },
  {
    name: ":type",
    component: Result,
  },
];
